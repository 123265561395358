// external
import { Box, Button, Typography, useTheme } from '@mui/material';
import React from 'react';
import { MdAdd } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

// components
import { GoalsEmptyPageContent, GoalsItem, ModalCard } from 'components';

// hooks
import { useGoals, useLocalization } from 'hooks';

// types
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  useBaseLanguage,
  useOrganization,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { IGoal, IRelationship } from '@guider-global/shared-types';
import { Divider } from '@guider-global/ui';
import { IButtonAction } from 'components/ActionButton';
import { useRelationships } from '@guider-global/front-end-hooks';

interface IRelationshipGoalsManageProps {
  relationship: IRelationship;
  handleClose: () => void;
}

export const RelationshipGoalsManage: React.FC<
  IRelationshipGoalsManageProps
> = ({ relationship, handleClose }) => {
  const { relationshipId = '' } = useParams<{
    relationshipId: string;
  }>();

  // Organization
  const organizationSlug = getSubDomain();
  const { organization } = useOrganization({
    organizationSlug,
  });
  //style
  const theme = useTheme();

  //baseLanguage
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  //hooks

  const { isLoadingRelationships, isMutatingRelationships } = useRelationships(
    {},
  );
  const navigate = useNavigate();

  const { isLoadingGoals, isMutatingGoals } = useGoals({});

  // Get goals data

  const programSlug = relationship.programSlug;
  const { getProgram } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });
  const program = getProgram(programSlug ?? '');

  const customGoalCategories =
    program?.registration?.goals?.custom_goal_categories?.map(
      (customCategory) => ({
        label: customCategory.goal_category_name,
        slug: customCategory.goal_category_slug.current,
        icon: customCategory.goal_category_icon?.name,
      }),
    ) ?? [];

  const organizationGoalCategories =
    organization?.goal_categories?.categories?.map((goalCategory) => ({
      label: goalCategory.goal_category_name,
      slug: goalCategory.goal_category_slug.current,
      icon: goalCategory.goal_category_icon?.name,
    })) ?? [];

  const goalCategories =
    organizationGoalCategories.concat(customGoalCategories);
  const goals = relationship?.goals as Partial<IGoal>[];

  const relationshipGoals = goals.filter(
    (goal) => goal.relationshipId === relationshipId,
  );

  //modal actions
  const modalActions: IButtonAction[] = [
    {
      label: baseLanguage?.globals?.common?.close_button_label,
      color: 'info',
      variant: 'contained',
      action: handleClose,
      isLoadingButton: true,
      dataCyLabel: 'relationship-manage-goals-modal-close-action-button',
    },
  ];

  //renderers
  const renderContent = () => {
    if (relationshipGoals.length === 0) {
      return (
        <GoalsEmptyPageContent
          createPath={`/relationships/${relationship.id}/goals/create`}
        />
      );
    }

    return (
      <>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            pb: 2,
          }}
        >
          <Button
            data-cy="components_Relationships_RelationshipGoals_RelationshipGoalsManage_manage-button"
            startIcon={<MdAdd />}
            onClick={() =>
              navigate(`/relationships/${relationship.id}/goals/create`)
            }
            color="info"
            variant="outlined"
            sx={{ fontWeight: 600 }}
          >
            {baseLanguage?.globals?.goals?.create_goal_button_label}
          </Button>
        </Box>

        {goals.length !== 0 && (
          <Divider sx={{ pb: 2 }}>
            <Typography variant="subtitle2" color={'text.secondary'}>
              {baseLanguage?.relationships?.goals?.manage_goals_modal
                ?.relationship_goals_divider_text ?? 'Relationship goals'}
            </Typography>
          </Divider>
        )}
        {goals.length !== 0 &&
          goals.map((goal) => {
            const goalCategory = goalCategories.find(
              (goalCategory) => goalCategory.slug === goal.categorySlug,
            );
            return (
              <GoalsItem
                key={`relationship-goals-${goal.id}`}
                goal={goal}
                sx={{ mb: 2 }}
                viewPath={`/relationships/${relationship.id}/goals/${goal.id}`}
                goalName={goalCategory?.label}
                goalSlug={goalCategory?.slug}
                goalIcon={goalCategory?.icon}
              />
            );
          })}
      </>
    );
  };

  return (
    <ModalCard
      title={baseLanguage?.relationships?.goals?.manage_goals_modal?.title}
      description={
        baseLanguage?.relationships?.goals?.manage_goals_modal?.subtitle
      }
      handleClose={handleClose}
      actions={modalActions}
      isLoading={
        isLoadingRelationships ||
        isLoadingGoals ||
        isMutatingGoals ||
        isMutatingRelationships
      }
      childrenSx={{
        borderBottom: `1px solid ${theme.palette.divider}`,
        overflowY: 'auto',
        p: 2,
      }}
      data-cy="relationship-manage-goals-modal"
      closeIconButtonDataCy="relationship-manage-goals-modal-close-icon-button"
    >
      {renderContent()}
    </ModalCard>
  );
};
