import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import Person from '@mui/icons-material/Person';
import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { useLocalization } from 'hooks';

// Hooks

export const RelationshipsEmpty = () => {
  const theme = useTheme();

  const organizationSlug = getSubDomain();

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        py: 1,
        px: 1.5,
        mt: 1,
      }}
      data-cy="no-relationships-list"
    >
      <Avatar
        variant="rounded"
        sx={{ width: 29, height: 29, backgroundColor: '#EEEEEE', mr: 1 }}
        data-cy="no-relationships-list-avatar"
      >
        <Person sx={{ color: theme.palette.action.active }} />
      </Avatar>
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{ letterSpacing: '0.4px' }}
        data-cy="no-relationships-list-title"
      >
        {baseLanguage?.relationships?.relationships_list?.no_relationships}
      </Typography>
    </Box>
  );
};
