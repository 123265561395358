//external
import {
  Box,
  Button,
  ButtonBase,
  IconButton,
  lighten,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useContext } from 'react';

//types
import { INote } from '@guider-global/shared-types';
import Edit from '@mui/icons-material/Edit';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import People from '@mui/icons-material/People';

//hooks
import { useLocalization, useMobileMediaQuery } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';

import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { INoteInputs } from 'forms';
import { defaultNoteValues, RelationshipNotesModalContext } from 'modals';
import { useFormContext } from 'react-hook-form';

interface IRelationshipNotesList {
  notes: Partial<INote>[];
  isExpanded?: boolean;
  handleExpand: (expand?: boolean) => void;
}

export const RelationshipNotesList: React.FC<IRelationshipNotesList> = ({
  notes,
  isExpanded,
  handleExpand,
}) => {
  //hooks
  const organizationSlug = getSubDomain();
  const navigate = useNavigate();
  const { relationshipId, noteId } = useParams();
  const { setOnConfirmDiscardNoteChanges, setIsDiscardNoteChangesModalOpen } =
    useContext(RelationshipNotesModalContext);

  const {
    reset,
    formState: { isDirty },
  } = useFormContext<INoteInputs>();

  //baseLanguage
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  //style
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  const notesCount = notes ? notes?.length : 0;
  const notesCountDescription =
    notesCount === 1
      ? `${notesCount} ${baseLanguage?.relationships?.notes?.notes_modal?.notes_sidebar?.notes_sidebar_single_note_label}`
      : `${notesCount} ${baseLanguage?.relationships?.notes?.notes_modal?.notes_sidebar?.notes_sidebar_multiple_notes_label}`;

  const navigateToNote = (noteId: string | undefined) => {
    navigate(`/relationships/${relationshipId}/notes/${noteId}`);
  };

  const navigateToNoteCreate = () => {
    navigate(`/relationships/${relationshipId}/notes/create`);
  };

  const handleClick = (callback: () => void) => {
    if (isMobile) {
      handleExpand(false);
    }
    if (isDirty) {
      setIsDiscardNoteChangesModalOpen(true);
      setOnConfirmDiscardNoteChanges(() => callback);
    } else {
      reset(defaultNoteValues);
      callback();
    }
  };

  //renders
  const renderNotesItems = () =>
    notes.map((note) => (
      <ButtonBase
        key={`notes-list-item-${note.id}`}
        onClick={() => handleClick(() => navigateToNote(note.id))}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderRadius: 0,
          textTransform: 'unset !important',
          ...(note.id === noteId && {
            backgroundColor: `${lighten(theme.palette.info.main, 0.9)}`,
          }),
          py: 1,
          pr: 0,
          pl: isExpanded ? 2 : 0,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
        disabled={note.id === noteId}
        data-cy={`relationship-note-${note.id}`}
      >
        <Typography
          variant="body2"
          color="text.primary"
          sx={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {note.title}
        </Typography>

        {note.isPrivate || !isExpanded ? undefined : (
          <People
            color="disabled"
            sx={{ ml: 2, opacity: note.isPrivate || !isExpanded ? 0 : '' }}
          />
        )}
      </ButtonBase>
    ));

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 0.25,
          paddingLeft: isExpanded ? { xs: 2, md: 3 } : 0,
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        {isExpanded && (
          <Typography
            color="text.secondary"
            variant="body2"
            data-cy="relationship-notes-list-note-count"
          >
            {notesCountDescription}
          </Typography>
        )}
        <IconButton
          sx={{ p: 0.75, alignSelf: 'flex-end' }}
          onClick={() => handleExpand()}
          data-cy="relationship-notes-list-open-icon-button"
        >
          {isExpanded ? (
            <KeyboardDoubleArrowLeftIcon color="info" />
          ) : (
            <KeyboardDoubleArrowRightIcon color="info" />
          )}
        </IconButton>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          gap: 1,
          p: 1,
        }}
        data-cy="relationship-notes-list"
      >
        {renderNotesItems()}
      </Box>
      {isExpanded ? (
        <Button
          variant="outlined"
          color="info"
          startIcon={<Edit />}
          sx={{
            mx: { xs: 2, md: 3 },
            my: 2,
            fontWeight: 600,
            whiteSpace: 'nowrap',
          }}
          onClick={() => handleClick(navigateToNoteCreate)}
          data-cy="relationship-notes-list-create-note-button"
        >
          {
            baseLanguage?.relationships?.notes?.notes_modal?.notes_sidebar
              ?.notes_sidebar_new_note_button_label
          }
        </Button>
      ) : (
        <IconButton
          sx={{ p: 0.75, my: 2, alignSelf: 'center' }}
          onClick={() => handleClick(navigateToNoteCreate)}
          data-cy="relationship-notes-list-create-note-button"
        >
          <Edit color="info" />
        </IconButton>
      )}
    </>
  );
};
