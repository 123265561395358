// external
import { Card } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// components
import { IAttendee, ModalCard, SessionsDetails } from 'components';

// Hooks
import { useBaseLanguage } from '@guider-global/sanity-hooks';

// types
import {
  getISOStringWithoutMilliseconds,
  getSubDomain,
} from '@guider-global/front-end-utils';
import { IRelationship, ISession } from '@guider-global/shared-types';
import { IButtonAction } from 'components/ActionButton';
import { useLocalization, useMixpanelEvents } from 'hooks';

interface IRelationshipSessionsViewProps {
  relationship: IRelationship;
  handleClose: () => void;
  isGroupProgram: boolean;
  isActiveGroup: boolean;
  isGuide: boolean;
  timezone: string;
}

export const RelationshipSessionsView: React.FC<
  IRelationshipSessionsViewProps
> = ({
  relationship,
  handleClose,
  isGroupProgram,
  isActiveGroup,
  isGuide,
  timezone,
}) => {
  const canSeeEditSessionButton =
    !relationship.isConcluded &&
    ((isGroupProgram && isActiveGroup && isGuide) || !isGroupProgram);

  //base language
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  //hooks
  const { trackMixpanelEvent } = useMixpanelEvents();
  const navigate = useNavigate();

  //sessions
  const { sessionId } = useParams();
  const relationshipSessions = relationship?.sessions as ISession[];
  const session = relationshipSessions.find(
    (session) => session.id === sessionId,
  );

  useEffect(() => {
    if (!session) return;
    trackMixpanelEvent('Relationship - Session - Session viewed', {
      Viewed: getISOStringWithoutMilliseconds(),
    });
  }, [session, trackMixpanelEvent]);

  if (!session) {
    return <></>;
  }

  //handlers
  const handleEditSession = () => {
    navigate(`/relationships/${relationship.id}/sessions/${sessionId}/edit`);
  };

  //modal actions
  const modalActions: IButtonAction[] = [
    {
      label: baseLanguage?.globals?.common?.close_button_label,
      variant: 'contained',
      color: 'info',
      action: handleClose,
      dataCyLabel: 'relationship-view-session-modal-close-button',
    },
  ];

  if (canSeeEditSessionButton) {
    modalActions.unshift({
      label: baseLanguage?.globals?.sessions?.edit_session_button_label,
      variant: 'outlined',
      color: 'info',
      action: handleEditSession,
      dataCyLabel: 'relationship-view-session-modal-edit-session-button',
    });
  }

  const attendees: IAttendee[] = [
    ...(relationship.guideProfiles ?? []),
    ...(relationship.traineeProfiles ?? []),
  ].map((profile) => {
    const user = relationship.users?.find((user) => user.id === profile.userId);
    return {
      ...profile,
      userPicture: user?.picture,
    };
  });

  return (
    <ModalCard
      title={baseLanguage?.relationships?.sessions?.view_session_modal?.title}
      actions={modalActions}
      handleClose={handleClose}
      width={'450px'}
      data-cy="relationship-view-session-modal"
      closeIconButtonDataCy="relationship-view-session-modal-close-icon-button"
    >
      <Card
        variant="outlined"
        sx={{ backgroundColor: '#FAFAFA', widht: '100%', p: 2 }}
      >
        {
          <SessionsDetails
            session={session}
            attendees={attendees}
            timezone={timezone}
          />
        }
      </Card>
    </ModalCard>
  );
};
