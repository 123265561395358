// external
import { FC } from 'react';
import { Typography } from '@mui/material';

export interface SettingsDescriptionProps {
  description: string | undefined;
}

export const SettingsDescription: FC<SettingsDescriptionProps> = ({
  description,
}) => {
  return (
    <>
      <Typography variant="body2" component="p" color="text.secondary">
        {description}
      </Typography>
    </>
  );
};
