// external
import { format, toDate } from 'date-fns-tz';
import React, { useMemo, useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// internal
import { getSubDomain } from '@guider-global/front-end-utils';
import { useLocalization, useMobileMediaQuery, useNotes } from 'hooks';

// types
import { INote, IRelationship } from '@guider-global/shared-types';

import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { ITypedApiResult } from '@guider-global/swr';
import { Box } from '@mui/material';
import { ActionButton, IButtonAction } from 'components/ActionButton';
import { INoteInputs, NotesCreateForm } from 'forms/Notes/NotesCreateForm';
import { ConfirmationModal, defaultNoteValues } from 'modals';
import { useUsers } from '@guider-global/front-end-hooks';

interface IRelationshipNoteProps {
  relationship: IRelationship;
}

export const RelationshipNote: React.FC<IRelationshipNoteProps> = ({
  relationship,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  //hooks
  const isMobile = useMobileMediaQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const { noteId, relationshipId } = useParams();
  const isCreate = location.pathname.endsWith('create');
  const organizationSlug = getSubDomain();

  const { users } = useUsers({});
  const user = users?.at(0);
  const {
    isLoadingNotes,
    reqNotes,
    isMutatingNotes,
    notes = [],
  } = useNotes({
    params: {
      relationshipId: relationshipId,
    },
  });

  const currentNote = useMemo(
    () => notes?.find((note) => note.id === noteId),
    [notes, noteId],
  );
  const isNoteOwner = currentNote?.ownerId === user?.id;

  const isLoading = isMutatingNotes || isLoadingNotes;

  //baseLanguage
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });
  const cancelButtonLabel =
    baseLanguage?.globals?.common?.cancel_button_label ?? 'Cancel';

  //form

  const {
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useFormContext<INoteInputs>();

  //handlers

  const onSubmit: SubmitHandler<INoteInputs> = async (data) => {
    const { isPublic, ...rest } = data;
    if (isCreate) {
      await reqNotes(
        {
          method: 'POST',
          url: `/notes`,
          data: {
            ...rest,
            isPrivate: !isPublic,
            relationshipId: relationshipId,
          },
        },
        {
          onSuccess: (response) => {
            const notesCreateResponseData = response.data.at(0)?.id;
            if (!notesCreateResponseData) {
              console.error('No note returned during create');
            }
            navigate(
              `/relationships/${relationshipId}/notes/${notesCreateResponseData}`,
            );
          },
          onError: (err) => {
            console.error('Something went wrong', err);
          },
          populateCache: (result, currentData) => {
            const newNotes = result.data;
            const oldNotes = currentData?.data ?? [];

            const newCache = {
              ...currentData,
              data: [...oldNotes, ...newNotes],
            } as ITypedApiResult<INote[]>;
            return newCache;
          },
        },
      );
    } else {
      await reqNotes(
        {
          method: 'PATCH',
          url: `/notes/${currentNote?.id}`,
          data: {
            ...rest,
            isPrivate: !isPublic,
          },
        },
        {
          populateCache: (result, currentData) => {
            const newNotes = result.data;
            const replaceDuplicateNotes =
              currentData?.data.map((note) => {
                const newNote = newNotes.find(
                  (newNote) => newNote.id === note.id,
                );
                if (newNote) {
                  return newNote;
                }
                return note;
              }) ?? [];

            const newCache = {
              ...currentData,
              data: [...replaceDuplicateNotes],
            } as ITypedApiResult<INote[]>;
            return newCache;
          },
        },
      );
    }
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteNote = async () => {
    await reqNotes(
      {
        method: 'DELETE',
        url: `/notes/${currentNote?.id}`,
      },
      {
        onSuccess: () => {
          handleDeleteModalClose();
          navigate(`/relationships/${relationshipId}/notes`);
        },
        populateCache: (result, currentData) => {
          reset(defaultNoteValues);
          const removedIds = result.data.map((note) => note.id);
          const filterRemoved =
            currentData?.data.filter((note) => !removedIds.includes(note.id)) ??
            [];
          return {
            ...currentData,
            data: filterRemoved,
          } as ITypedApiResult<INote[]>;
        },
      },
    );
  };

  //modal actions
  const createActions: IButtonAction[] = [
    {
      label:
        baseLanguage?.relationships?.notes?.notes_modal
          ?.notes_modal_create_edit_note?.save_note_button_label,
      variant: 'contained',
      color: 'info',
      action: handleSubmit(onSubmit),
      isLoadingButton: true,
      disabled: !isDirty,
      dataCyLabel: 'relationship-notes-modal-create-note-button',
    },
  ];

  const patchActions: IButtonAction[] = [
    ...(isNoteOwner
      ? [
          {
            label: baseLanguage?.globals?.common?.delete_button_label,
            variant: 'text',
            color: 'error',
            action: () => setIsDeleteModalOpen(true),
            isLoadingButton: true,
            dataCyLabel: 'relationship-notes-modal-delete-note-button',
          } as IButtonAction,
        ]
      : []),
    {
      label:
        baseLanguage?.relationships?.notes?.notes_modal
          ?.notes_modal_create_edit_note?.save_note_button_label,
      variant: 'contained',
      color: 'info',
      action: handleSubmit(onSubmit),
      isLoadingButton: true,
      disabled: !isDirty,
      dataCyLabel: 'relationship-notes-modal-update-note-button',
    },
  ];
  const actions = isCreate ? createActions : patchActions;
  const deleteModalActions: IButtonAction[] = [
    {
      label: cancelButtonLabel,
      action: handleDeleteModalClose,
      color: 'info',
      variant: 'outlined',
      dataCyLabel: 'relationship-notes-modal-delete-note-modal-discard-button',
    },
    {
      label: baseLanguage?.globals?.common?.delete_button_label,
      action: handleDeleteNote,
      color: 'error',
      variant: 'contained',
      isLoadingButton: true,
      dataCyLabel: 'relationship-notes-modal-delete-note-modal-confirm-button',
    },
  ];
  const guideProfiles = relationship.guideProfiles ?? [];
  const traineeProfiles = relationship.traineeProfiles ?? [];

  const profiles = [...guideProfiles, ...traineeProfiles];

  const ownersProfile = profiles?.find(
    (guideProfile) => guideProfile.userId === currentNote?.ownerId,
  );

  const lastUpdatedByProfile = profiles.find(
    (profile) => profile.userId === currentNote?.lastUpdatedBy,
  );

  const currentNoteUpdateAt =
    currentNote?.updatedAt &&
    format(toDate(currentNote.updatedAt), 'MMMM d yyyy');

  const currentNoteCreatedAt =
    currentNote?.createdAt &&
    format(toDate(currentNote.createdAt), 'MMMM d yyyy');

  const lastUpdatedByString =
    currentNote &&
    `${baseLanguage?.relationships?.notes?.notes_modal?.notes_modal_create_edit_note?.note_status?.note_status_last_updated_by_label} ${lastUpdatedByProfile?.firstName} ${lastUpdatedByProfile?.lastName} ${baseLanguage?.relationships?.notes?.notes_modal?.notes_modal_create_edit_note?.note_status?.note_status_last_updated_on_label} ${currentNoteUpdateAt}`;

  const createdByString =
    currentNote &&
    `${baseLanguage?.relationships?.notes?.notes_modal?.notes_modal_create_edit_note?.note_status?.note_status_created_by_label} ${ownersProfile?.firstName} ${ownersProfile?.lastName} ${baseLanguage?.relationships?.notes?.notes_modal?.notes_modal_create_edit_note?.note_status?.note_status_created_on_label} ${currentNoteCreatedAt}`;
  return (
    <>
      <NotesCreateForm
        handleSubmit={handleSubmit(onSubmit)}
        lastUpdatedBy={lastUpdatedByString}
        createdBy={createdByString}
        note={currentNote}
        isLoading={isLoading || !currentNote}
      />
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: isNoteOwner ? 'space-between' : 'flex-end',
        }}
      >
        {actions.map((action) => (
          <ActionButton
            isLoading={isLoading}
            buttonAction={action}
            key={`modal-button-${action.label}`}
          />
        ))}
      </Box>
      <ConfirmationModal
        handleClose={handleDeleteModalClose}
        open={isDeleteModalOpen}
        title={
          baseLanguage?.relationships?.notes?.notes_modal
            ?.notes_modal_create_edit_note?.delete_note_modal
            ?.discard_changes_title
        }
        description={
          baseLanguage?.relationships?.notes?.notes_modal
            ?.notes_modal_create_edit_note?.delete_note_modal
            ?.discard_changes_description
        }
        actions={deleteModalActions}
        isLoading={isLoading}
        data-cy="relationship-notes-modal-delete-note-modal"
        closeIconButtonDataCy="relationship-notes-modal-delete-note-modal-close-icon-button"
      />
    </>
  );
};
