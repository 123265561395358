// external
import React from 'react';
import { Box, Skeleton } from '@mui/material';

// components
import { RelationshipItem, RelationshipsEmpty } from 'components';

// types
import { IRelationship } from '@guider-global/shared-types';

interface IRelationshipsListProps {
  relationships: IRelationship[];
  isLoading: boolean;
}

export const RelationshipsList: React.FC<IRelationshipsListProps> = ({
  relationships,
  isLoading,
}) => {
  if (isLoading)
    return (
      <>
        <Skeleton sx={{ height: '45px', mt: 1, transform: 'none' }} />
        <Skeleton sx={{ height: '45px', mt: 1, transform: 'none' }} />
        <Skeleton sx={{ height: '45px', mt: 1, transform: 'none' }} />
      </>
    );

  return (
    <Box data-cy="active-relationships-list">
      {relationships.length === 0 ? (
        <RelationshipsEmpty />
      ) : (
        relationships.map((relationship) => (
          <RelationshipItem
            key={`relationship-${relationship.id}`}
            relationship={relationship}
          />
        ))
      )}
    </Box>
  );
};
