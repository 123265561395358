// external
import React, { useState } from 'react';
import { Control, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

// components
import { IGoalInputs, ModalCard } from 'components';
import GoalsEditForm from 'forms/GoalsEditForm';
import { ConfirmationModal } from 'modals';

// hooks
import { useGoals, useLocalization } from 'hooks';

// types
import { IRelationship } from '@guider-global/shared-types';

import { IButtonAction } from 'components/ActionButton';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { useRelationships } from '@guider-global/front-end-hooks';

interface IRelationshipGoalsEdit {
  relationship: IRelationship;
  handleClose: () => void;
}

export const RelationshipGoalsEdit: React.FC<IRelationshipGoalsEdit> = ({
  relationship,
  handleClose,
}) => {
  //State
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);

  //baseLanguage
  const organizationSlug = getSubDomain();

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const discardChangesButtonLabel =
    baseLanguage?.globals?.common?.discard_changes_button_label ??
    'Discard Changes';

  //hooks
  const navigate = useNavigate();
  const { goalId } = useParams();

  //form
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm<IGoalInputs>({ mode: 'onChange' });

  //goals
  const { isLoadingGoals, reqGoals, goals, revalidateGoals, isMutatingGoals } =
    useGoals({});
  const { relationshipsRevalidate, isMutatingRelationships } = useRelationships(
    {},
  );

  const goal = goals?.find((goal) => goal.id === goalId);

  if (!goal?.createdAt) {
    return <></>;
  }

  const typedControl: Control = control as unknown as Control;

  //handlers
  const onSubmit: SubmitHandler<IGoalInputs> = async (data) => {
    const { data: goalsResponseData } = await reqGoals({
      method: 'PATCH',
      url: `/goals/${goalId}`,
      data,
    });

    if (!goalsResponseData) {
      return;
    }

    navigateBack();
    revalidateGoals();
    relationshipsRevalidate();
  };

  const navigateBack = () => {
    navigate(-1);
  };

  const handleDiscardChanges = () => {
    if (!isDirty) {
      navigateBack();
    } else {
      setIsDiscardModalOpen(true);
    }
  };

  const handleDiscardModalClose = () => {
    setIsDiscardModalOpen(false);
  };

  //modal actions

  const contentModalActions: IButtonAction[] = [
    {
      label: baseLanguage?.globals?.common?.go_back_button_label,
      variant: 'outlined',
      color: 'info',
      action: handleDiscardChanges,
      dataCyLabel:
        'components_Relationships_RelationshipGoals_RelationshipGoalsEdit_edit-button',
    },
    {
      label: baseLanguage?.globals?.common?.save_changes_button_label,
      variant: 'contained',
      color: 'info',
      disabled: !isValid || !isDirty,
      isLoadingButton: true,
      action: handleSubmit(onSubmit),
      dataCyLabel:
        'components_Relationships_RelationshipGoals_RelationshipGoalsEdit_submit-button',
    },
  ];

  const discardModalActions: IButtonAction[] = [
    {
      label: baseLanguage?.globals?.common?.cancel_button_label,
      action: handleDiscardModalClose,
      color: 'info',
      dataCyLabel:
        'components_Relationships_RelationshipGoals_RelationshipGoalsEdit_discard-close-button',
    },
    {
      label: discardChangesButtonLabel,
      action: () => {
        navigateBack();
      },
      color: 'error',
      dataCyLabel:
        'components_Relationships_RelationshipGoals_RelationshipGoalsEdit_disarc-confirm-button',
    },
  ];

  return (
    <ModalCard
      title={
        baseLanguage?.relationships?.goals?.create_and_update_goal?.update_goal
          ?.title
      }
      handleClose={handleClose}
      actions={contentModalActions}
      isLoading={isLoadingGoals || isMutatingGoals || isMutatingRelationships}
    >
      <GoalsEditForm
        relationship={relationship}
        goal={goal}
        handleSubmit={handleSubmit(onSubmit)}
        control={typedControl}
        errors={errors}
        isValid={isValid}
        isDirty={isDirty}
        isLoadingGoals={isLoadingGoals}
        discardAction={navigateBack}
        hideButtons
      />
      <ConfirmationModal
        open={isDiscardModalOpen}
        title={baseLanguage?.relationships?.goals?.discard_goal_modal?.title}
        description={
          baseLanguage?.relationships?.goals?.discard_goal_modal?.description
        }
        actions={discardModalActions}
        handleClose={handleDiscardModalClose}
      />
    </ModalCard>
  );
};
