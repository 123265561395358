// external
import { LoadingButton } from '@mui/lab';
import { Box, ThemeProvider, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

//internal

// components
import { TextStack, theme } from '@guider-global/ui';
import {
  RelationshipDiscardNoteChangesModal,
  RelationshipNotesModal,
  RelationshipNotesModalProvider,
} from 'modals';
//hooks

//types
import { IRelationship } from '@guider-global/shared-types';
import { deepMerge } from 'utils';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useLocalization } from 'hooks';
import { useBaseLanguage } from '@guider-global/sanity-hooks';

interface IRelationshipNotesProps {
  relationship: IRelationship;
}

export const RelationshipNotes: React.FC<IRelationshipNotesProps> = ({
  relationship,
}) => {
  //state
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);

  //style

  //hooks
  const navigate = useNavigate();
  const organizationSlug = getSubDomain();
  const { relationshipId } = useParams();
  const { pathname } = useLocation();

  //base language
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const organizationTheme = useTheme();
  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );

  //handlers

  const handleNotesModalClose = () => {
    setIsNotesModalOpen(false);

    navigate(`/relationships/${relationship.id}`);
  };

  const handleViewNotes = () => {
    setIsNotesModalOpen(true);

    navigate(`/relationships/${relationship.id}/notes`);
  };

  const handleCreateNote = () => {
    setIsNotesModalOpen(true);

    navigate(`/relationships/${relationship.id}/notes/create`);
  };

  useEffect(() => {
    if (isNotesModalOpen) return;

    if (pathname.includes('/relationships') && pathname.includes('/notes')) {
      setIsNotesModalOpen(true);
    }
  }, [isNotesModalOpen, pathname, relationshipId]);

  return (
    <>
      <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
        <TextStack
          size="xs"
          align="left"
          heading={{
            variant: 'h6',
            component: 'p',
            text: baseLanguage?.relationships?.notes?.note_sidebar
              ?.note_sidebar_title,
            'data-cy': 'relationship-notes-title',
          }}
          sx={{
            fontWeight: 500,
            mt: 3,
          }}
        />
        <Typography
          variant="caption"
          sx={{ fontWeight: 500 }}
          color="text.disabled"
          data-cy="relationship-notes-description"
        >
          {
            baseLanguage?.relationships?.notes?.note_sidebar
              ?.note_sidebar_description
          }
        </Typography>
        <Box
          onClick={handleCreateNote}
          sx={{
            mt: 3,
            my: 1.5,
            width: '100%',
            minHeight: '83px',
            borderRadius: '5px',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            transition: 'ease-in-out 0.2s',
            ':hover': {
              border: '1px solid rgba(0, 0, 0, 1)',
              cursor: 'text',
            },
          }}
          data-cy="relationship-create-note-container"
        >
          <Typography
            sx={{ pt: 1.5, px: 2 }}
            variant="body2"
            color="text.disabled"
          >
            {
              baseLanguage?.relationships?.notes?.note_sidebar
                ?.note_sidebar_input_label
            }
          </Typography>
        </Box>

        <LoadingButton
          data-cy="relationship-view-notes-button"
          fullWidth
          variant="outlined"
          color="info"
          sx={{
            fontWeight: 600,
            mb: 2,
            px: 0.5,
            py: 1,
          }}
          onClick={handleViewNotes}
        >
          {
            baseLanguage?.relationships?.notes?.note_sidebar
              ?.note_sidebar_view_button_label
          }
        </LoadingButton>
      </ThemeProvider>
      <RelationshipNotesModalProvider relationshipId={relationship.id}>
        <RelationshipNotesModal
          relationship={relationship}
          isOpen={isNotesModalOpen}
          handleClose={handleNotesModalClose}
        />
        <RelationshipDiscardNoteChangesModal />
      </RelationshipNotesModalProvider>
    </>
  );
};

export * from './RelationshipNotesEmpty';
export * from './RelationshipNotesList';
export * from './RelationshipNote';
