// external
import React from 'react';
import { useNavigate } from 'react-router-dom';

// hooks

// types
import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { ModalCard } from 'components';
import { IButtonAction } from 'components/ActionButton';
import { IntegrationsList } from 'components/IntegrationsList';
import { useLocalization } from 'hooks';

interface IRelationshipSessionsIntegrateProps {
  handleClose: () => void;
}

export const RelationshipSessionsIntegrate: React.FC<
  IRelationshipSessionsIntegrateProps
> = ({ handleClose }) => {
  //hooks
  const navigate = useNavigate();
  const organizationSlug = getSubDomain();

  //base language

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const integrationsModal =
    baseLanguage?.relationships?.sessions?.manage_integrations_modal;

  //handlers
  const handleGoBack = () => {
    navigate(-1);
  };

  //modal actions
  const modalGoBackAction: IButtonAction = {
    label: baseLanguage?.globals?.common?.go_back_button_label,
    action: handleGoBack,
    variant: 'contained',
    color: 'info',
    dataCyLabel:
      'components_Relationships_RelationshipSessions_RelationshipSesssionsIntegrate_cancel-button',
  };

  return (
    <ModalCard
      title={integrationsModal?.title}
      description={integrationsModal?.description}
      handleClose={handleClose}
      actions={[modalGoBackAction]}
    >
      <IntegrationsList />
    </ModalCard>
  );
};
