import {
  useRelationships,
  useMemberships,
} from '@guider-global/front-end-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useOrganizationPrograms } from '@guider-global/sanity-hooks';
import { IRelationship, IGroup } from '@guider-global/shared-types';
import { ButtonStack, ButtonStackItem, Text, theme } from '@guider-global/ui';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import ReportProblemOutlined from '@mui/icons-material/ReportProblemOutlined';
import { Box, Divider, ThemeProvider, useTheme } from '@mui/material';
import { useLocalization } from 'hooks';
import { deepMerge } from 'utils';

interface IRelationshipGroupAlertProps {
  relationship: IRelationship;
  isGuide: boolean;
}

export const RelationshipGroupAlert: React.FC<IRelationshipGroupAlertProps> = ({
  relationship,
  isGuide,
}) => {
  const sessionsLength = relationship.sessions
    ? relationship.sessions.length
    : 0;
  const hasScheduledAtLeastOneSession = sessionsLength > 0;
  const isArchivedRelationship = relationship.isConcluded;

  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);

  const { getProgram } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });
  const program = getProgram(relationship.programSlug ?? '');
  const organizationTheme = useTheme();
  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );
  const { palette } = organizationTheme;

  const { isLoadingMemberships, membershipsRevalidate, isMutatingMemberships } =
    useMemberships({});
  const {
    reqRelationships,
    isLoadingRelationships,
    isMutatingRelationships,
    relationshipsRevalidate,
  } = useRelationships({});

  const group = relationship?.group as Partial<IGroup>;

  let title: string = '';
  let description: string = '';
  let boxColor: string = '';
  let icon: React.ReactNode = <></>;
  let buttons: ButtonStackItem[] = [];

  const isActiveGroup =
    group?.activatedAt !== null && group?.activatedAt !== undefined;

  const traineeCount = relationship.traineeProfiles?.length ?? 0;
  const noTraineesHaveJoined = traineeCount === 0;

  const allTraineesHaveLeft = isActiveGroup && noTraineesHaveJoined;

  const guideGroupCopy =
    program?.program_details?.program_type?.program_type_text.variations?.group
      ?.relationships?.guide;

  const traineeGroupCopy =
    program?.program_details?.program_type?.program_type_text.variations?.group
      ?.relationships?.trainee;

  const guideAlertCopy = isActiveGroup
    ? guideGroupCopy?.group_activated
    : guideGroupCopy?.group_activation_pending;

  const traineeAlertCopy = isActiveGroup
    ? traineeGroupCopy?.group_activated
    : traineeGroupCopy?.group_activation_pending;

  function setAlertBoxColor() {
    if (isActiveGroup) {
      return palette.success.transparent;
    } else if (isGuide) {
      return palette.warning.transparent;
    }
    return palette.info.transparent;
  }

  const deactivateGroup = async () => {
    await reqRelationships({
      method: 'PATCH',
      url: `/relationships/${relationship?.id}`,
      data: {
        group: {
          activatedAt: null,
        },
      },
    });

    membershipsRevalidate();
    relationshipsRevalidate();
  };

  const activateGroup = async () => {
    if (noTraineesHaveJoined) {
      return;
    }

    await reqRelationships({
      method: 'PATCH',
      url: `/relationships/${relationship?.id}`,
      data: {
        group: {
          activatedAt: new Date(),
        },
      },
    });

    membershipsRevalidate();
    relationshipsRevalidate();
  };

  if (allTraineesHaveLeft) {
    title = guideGroupCopy?.trainees_left_group?.alert_title ?? '';
    description = guideGroupCopy?.trainees_left_group?.alert_description ?? '';
    boxColor = palette.error.transparent ?? '';
    icon = <ErrorOutline fontSize="small" color="error" />;
    buttons = [
      {
        variant: 'contained',
        color: 'success',
        label:
          guideGroupCopy?.trainees_left_group?.republish_group_button_label,
        key: 'republish',
        onClick: () => deactivateGroup(),
        loading:
          isLoadingRelationships ||
          isMutatingRelationships ||
          isMutatingMemberships,
      },
    ];
  } else {
    title = isGuide
      ? guideAlertCopy?.alert_title ?? ''
      : traineeAlertCopy?.alert_title ?? '';
    description = isGuide
      ? guideAlertCopy?.alert_description ?? ''
      : traineeAlertCopy?.alert_description ?? '';
    boxColor = setAlertBoxColor() ?? '';
    icon = <AlertIcon isActiveGroup={isActiveGroup} isGuide={isGuide} />;
    buttons = [
      {
        disabled: !isActiveGroup && noTraineesHaveJoined,
        variant: 'contained',
        color: 'success',
        label:
          guideGroupCopy?.group_activation_pending?.start_group_button_label,
        key: 'activation-button',
        onClick: () => activateGroup(),
        loading:
          isLoadingMemberships ||
          isLoadingRelationships ||
          isMutatingRelationships ||
          isMutatingMemberships,
      },
    ];
  }

  if (
    ((hasScheduledAtLeastOneSession && isActiveGroup) ||
      isArchivedRelationship) &&
    !allTraineesHaveLeft
  ) {
    return <></>;
  }

  return (
    <>
      <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pt: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'start',
              flexDirection: 'row',
              backgroundColor: boxColor,
              borderRadius: '3px',
              p: 2,
            }}
          >
            {icon}
            <Box sx={{ ml: 1 }}>
              <Text variant="h6" sx={{ fontWeight: 500 }} text={title} />
              <Text
                variant="body1"
                sx={{
                  fontWeight: 400,
                  mt: 1,
                  color: palette.text.secondary,
                }}
                text={description}
              />
            </Box>
          </Box>
          {isGuide && (
            <>
              {(!isActiveGroup || allTraineesHaveLeft) && (
                <ButtonStack
                  direction={'column'}
                  buttons={buttons ?? []}
                  spacing={1.5}
                  mt={2}
                />
              )}

              {noTraineesHaveJoined && (
                <Text
                  variant="body2"
                  color="text.disabled"
                  mt={1}
                  text={
                    guideGroupCopy?.group_activation_pending
                      ?.insufficient_members_message
                  }
                />
              )}
            </>
          )}
        </Box>
      </ThemeProvider>
      <Divider sx={{ mt: 3 }} />
    </>
  );
};

const AlertIcon = ({
  isActiveGroup,
  isGuide,
}: {
  isActiveGroup: boolean;
  isGuide: boolean;
}) =>
  isActiveGroup ? (
    <CheckCircleOutline fontSize="small" color="success" />
  ) : isGuide ? (
    <ReportProblemOutlined fontSize="small" color="warning" />
  ) : (
    <InfoOutlined fontSize="small" color="info" />
  );
