import { OrganizationContentContainer } from '@guider-global/ui';
import { Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export interface SettingsContainerProps {
  header?: string;
}

export const SettingsContainer: FC<
  PropsWithChildren<SettingsContainerProps>
> = ({ children, header }) => {
  return (
    <OrganizationContentContainer>
      <SettingsContainerHeader header={header} />
      {children}
    </OrganizationContentContainer>
  );
};

export const SettingsContainerHeader: FC<
  Pick<SettingsContainerProps, 'header'>
> = ({ header }) => {
  if (!header) return null;
  return (
    <Typography variant="h5" component="h2" sx={{ fontWeight: 600, mb: 1 }}>
      {header}
    </Typography>
  );
};
