//external
import { Modal } from '@mui/material';

//components
import { ModalCard } from 'components/ModalCard';

//types
import { ProfilePreview } from 'components';
import {
  MembershipDataProps,
  ProfilePreviewProfileData,
} from 'components/ProfilePreview';

interface RelationshipViewProfileModalProps {
  isOpen: boolean;
  title: string | undefined;
  closeButtonLabel: string | undefined;
  handleClose: () => void;
  closeButtonAction: () => void;
  programSlug: string | undefined;
  userRole: 'guide' | 'trainee';
  profileData: ProfilePreviewProfileData;
  membershipData: MembershipDataProps;
  connectAction?: () => void;
  isLoading?: boolean;
}

export function RelationshipViewProfileModal({
  isOpen,
  title,
  closeButtonLabel,
  handleClose,
  closeButtonAction,
  programSlug,
  userRole,
  profileData,
  membershipData,
  connectAction,
  isLoading = false,
}: RelationshipViewProfileModalProps) {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalCard
        childrenSx={{ overflowY: 'auto', maxWidth: '100%' }}
        title={title}
        actions={[{ label: closeButtonLabel, action: closeButtonAction }]}
      >
        <ProfilePreview
          programSlug={programSlug}
          userRole={userRole}
          profileData={profileData}
          membershipData={membershipData}
          action={connectAction}
          isLoading={isLoading}
          disabled={isLoading}
        />
      </ModalCard>
    </Modal>
  );
}
