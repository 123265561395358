import { datadogLogs } from '@guider-global/datadog';
import {
  useBaseLanguage,
  useOrganization,
  useOrganizationPrograms,
  useSettings,
} from '@guider-global/sanity-hooks';
import { createTheme } from '@mui/material';
import { LoadingElement } from 'components';
import { ThemeContext } from 'context/theme';
import { useLocalization } from 'hooks';
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useIntercom } from 'react-use-intercom';
import { useAppDispatch } from 'store/hooks';
import {
  RegistrationTypes,
  showAppAlert,
  startAppLoading,
  stopAppLoading,
  updateRegistrationType,
} from 'store/slices/appSlice';
let { version: REACT_APP_VERSION } = require('../../../package.json');

export interface SetupWrapperProps {
  organizationSlug: string;
  organizationGetSilently?: boolean;
  settingsGetSilently?: boolean;
  organizationProgramsGetSilently?: boolean;
}

export const SetupWrapper: FC<PropsWithChildren<SetupWrapperProps>> = ({
  organizationSlug,
  organizationGetSilently = true,
  organizationProgramsGetSilently = true,
  children,
}) => {
  // Context
  const { theme, setTheme } = useContext(ThemeContext);
  // Local State
  const [loading, setLoading] = useState<boolean>(true);

  // Redux
  const dispatch = useAppDispatch();

  // Hooks
  // - Localization
  const { localeCode } = useLocalization(organizationSlug);
  // - Intercom
  const { boot: bootIntercom } = useIntercom();
  // - Sanity BaseLanguage

  const { isLoadingBaseLanguage, isErrorBaseLanguage, errorBaseLanguage } =
    useBaseLanguage({ localeCode });

  // - Sanity Organization
  const {
    isLoadingOrganization,
    isErrorOrganization,

    errorOrganization,
    organization,
  } = useOrganization({
    organizationSlug,

    localeCode,
  });

  const sanityOrganizationAccess = organization?.access;
  const sanityOrganizationWhiteLabel = organization?.white_label;

  const isLocalAccountRegistration =
    sanityOrganizationAccess?.local_account_registration;
  const sanityOrganizationConfigurations =
    sanityOrganizationAccess?.configuration_array ?? [];
  const isSSOMultitenants = sanityOrganizationConfigurations.length > 0;

  // - Sanity Programs
  const { errorPrograms, isErrorPrograms, isLoadingPrograms } =
    useOrganizationPrograms({
      organizationSlug,

      localeCode,
    });

  // - Sanity Settings
  const { settings, isErrorSettings, isLoadingSettings, errorSettings } =
    useSettings({ localeCode });
  // Event
  const setCustomTheme = useCallback(() => {
    if (!sanityOrganizationWhiteLabel) return;
    const organizationColorPalette =
      sanityOrganizationWhiteLabel.color_palette_config.color_palette ?? {};
    const organizationColors = Object.entries(organizationColorPalette).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: {
          main: value.hex,
          transparent: `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}, 0.1)`,
        },
      }),
      {},
    );
    const customTheme = createTheme({
      ...theme,
      palette: {
        ...theme.palette,
        ...organizationColors,
      },
    });
    setTheme(customTheme);
    return customTheme;
  }, [setTheme, theme, sanityOrganizationWhiteLabel]);

  useEffect(() => {
    bootIntercom({
      customAttributes: {
        organizationSlug,
        appVersion: REACT_APP_VERSION,
      },
    });
  });

  useEffect(() => {
    if (
      (isLoadingOrganization && !errorOrganization) ||
      (isLoadingBaseLanguage && !errorBaseLanguage) ||
      (isLoadingSettings && !errorSettings) ||
      (isLoadingPrograms && !errorPrograms)
    ) {
      dispatch(startAppLoading(true));
      setLoading(true);
    }
    if (loading && organization) {
      setCustomTheme();
    }
    if (organization && !errorBaseLanguage && settings && !errorPrograms) {
      dispatch(stopAppLoading(true));
      setLoading(false);
    }
  }, [
    dispatch,
    isErrorSettings,
    loading,
    isLoadingSettings,
    setCustomTheme,
    settings,
    isLoadingBaseLanguage,
    isErrorBaseLanguage,
    errorBaseLanguage,
    errorSettings,
    isLoadingPrograms,
    errorPrograms,
    isLoadingOrganization,
    errorOrganization,
    organization,
  ]);

  useEffect(() => {
    const handleDispatchedError = (
      errorCode?: string,
      errorMessage?: string,
    ) => {
      const message = errorMessage ?? 'Undefined Error Message';
      console.error('error', { errorCode, errorMessage });
      datadogLogs.logger.error('setupWrapper: ', {
        errorCode,
        errorMessage,
      });
      dispatch(
        showAppAlert({
          severity: 'error',
          message: message,
          timeout: 10000,
        }),
      );
      dispatch(stopAppLoading(true));

      setLoading(false);
    };

    if (errorBaseLanguage) {
      const baseLanguageErrors = errorBaseLanguage;
      const errorCode = 'SanityBaseLanguage';
      const errorMessage = baseLanguageErrors.message;
      handleDispatchedError(errorCode, errorMessage);
    }
    if (isErrorOrganization) {
      const errorCode = 'SanityOrganization';
      const errorMessage = errorOrganization?.message;
      handleDispatchedError(errorCode, errorMessage);
    }
    if (isErrorSettings) {
      const errorCodes = 'SanitySettings';
      const errorMessages = `${errorSettings?.message}`;
      handleDispatchedError(errorCodes, errorMessages);
    }
    if (isErrorPrograms) {
      const errorCode = 'SanityOrganizationPrograms';
      const errorMessage = errorPrograms?.message;
      handleDispatchedError(errorCode, errorMessage);
    }
  }, [
    dispatch,
    errorSettings,
    isErrorSettings,
    errorBaseLanguage,
    isErrorPrograms,
    errorPrograms?.message,
    isErrorOrganization,
    errorOrganization?.message,
  ]);

  useEffect(() => {
    const handleUpdateRegistrationType = (key: RegistrationTypes) => {
      dispatch(updateRegistrationType(key));
    };

    if (loading) return;
    if (isLocalAccountRegistration && isSSOMultitenants) {
      handleUpdateRegistrationType('localAccountsAndSSO');
      return;
    }
    if (isLocalAccountRegistration) {
      handleUpdateRegistrationType('localAccounts');
      return;
    }
    if (isSSOMultitenants) {
      handleUpdateRegistrationType('multitenantsSSO');
      return;
    }
    handleUpdateRegistrationType('none');
  }, [dispatch, isLocalAccountRegistration, isSSOMultitenants, loading]);

  useEffect(() => {
    if (loading) return;

    const error = errorBaseLanguage;

    if (
      error?.message === 'Empty base languages' ||
      window.location.hostname === 'guider.app'
    ) {
      window.location.replace('https://guider-ai.com/');
    }
  }, [loading, errorBaseLanguage]);

  if (loading && !localeCode) return <LoadingElement />;

  return children;
};
