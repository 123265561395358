// external
import React from 'react';
import { Control, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// internal
import { getSubDomain } from '@guider-global/front-end-utils';
import { GoalsCreateForm } from 'forms';
import { useGoals, useLocalization } from 'hooks';

// types
import { IGoal, IRelationship } from '@guider-global/shared-types';

import { ModalCard } from 'components';
import { IButtonAction } from 'components/ActionButton';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { useRelationships } from '@guider-global/front-end-hooks';

interface IRelationshipGoalsCreateProps {
  relationship: IRelationship;
  handleClose: () => void;
}

export type IGoalInputs = {
  categorySlug: string;
  objective: string;
  outcomes: string;
  rationale: string;
};

export const RelationshipGoalsCreate: React.FC<
  IRelationshipGoalsCreateProps
> = ({ relationship, handleClose }) => {
  //hooks
  const navigate = useNavigate();

  const { isLoadingGoals, reqGoals, isMutatingGoals, revalidateGoals } =
    useGoals({});
  const { reqRelationships, isMutatingRelationships, relationshipsRevalidate } =
    useRelationships({});

  //baseLanguage
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  //form
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<IGoalInputs>({ mode: 'onChange' });
  const typedControl: Control = control as unknown as Control;

  //handlers
  const onSubmit: SubmitHandler<IGoalInputs> = async (data) => {
    Object.assign(data, {
      status: 'on_track',
      organizationSlug,
      relationshipId: relationship.id,
    });

    const { data: goalsResponseData } = await reqGoals({
      method: 'POST',
      url: '/goals',
      data,
    });

    const currentGoalIds = relationship.goals?.map((goal) => goal.id) ?? [];

    const newGoalIds = goalsResponseData?.map((goal: IGoal) => goal.id) ?? [];

    await reqRelationships({
      method: 'PATCH',
      url: `/relationships/${relationship.id}`,
      data: {
        goals: [...(currentGoalIds as string[]), ...newGoalIds],
      },
    });

    navigate(`/relationships/${relationship.id}/goals`);
    revalidateGoals();
    relationshipsRevalidate();
  };

  //modal actions
  const contentModalActions: IButtonAction[] = [
    {
      label:
        baseLanguage?.relationships?.goals?.create_and_update_goal?.create_goal
          ?.discard_button_label,
      variant: 'outlined',
      color: 'info',
      action: () => navigate(-1),
      dataCyLabel:
        'components_Relationships_RelationshipGoals_RelationshipGoalsCreate_navigation-button',
    },
    {
      label: baseLanguage?.globals?.goals?.create_goal_button_label,
      variant: 'contained',
      color: 'info',
      action: handleSubmit(onSubmit),
      isLoadingButton: true,
      disabled: !isValid,
      dataCyLabel:
        'components_Relationships_RelationshipGoals_RelationshipGoalsCreate_submit-button',
    },
  ];

  return (
    <ModalCard
      title={
        baseLanguage?.relationships?.goals?.create_and_update_goal?.create_goal
          ?.title
      }
      handleClose={handleClose}
      actions={contentModalActions}
      isLoading={isLoadingGoals || isMutatingGoals || isMutatingRelationships}
    >
      <GoalsCreateForm
        handleSubmit={handleSubmit(onSubmit)}
        control={typedControl}
        errors={errors}
        isValid={isValid}
        isLoadingGoals={isLoadingGoals}
        discardAction={() => navigate(-1)}
        hideButtons
        relationship={relationship}
      />
    </ModalCard>
  );
};
